.shipping-report{
    padding:1%;
}
.shipping-select-wrapper{
    display: flex;
}

.shipping-select-wrapper #date-input{
    height:38px;
    width: 100%;
    border-radius: 4px;
}

.shipping-select-wrapper .react-bootstrap-daterangepicker-container {
    display:block !important;
}

.shipping-select-wrapper .daterangepicker {
    display: flex !important;
}
.shipping-select-wrapper .reporting-tooltip {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
}

.shipping-select-wrapper .daterangepicker.show-calendar .drp-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shipmentData .form-group-lg {
    margin-bottom: 0 !important;
}


/*---MANIFEST CSS---*/

.reporting-manifested .manifest-green {
    color: #0ea432;
}
.reporting-manifested .manifest-error {
    color: #ff5a4d;
}


/*---PENDING OVERVIEW CSS---*/
.shipmentData .flex-row {
    display: flex;
    align-items: center;
}
.shipmentData .pending-overview-header {
    font-weight:700;
    font-size: 17px;
}

.shipmentData .pending-overview-item {
    margin-bottom: 0.1em;
    margin-top: 0.1em;
    font-size: 16px;
}

.pending-overview-header .store-value {
    display: flex;
    justify-content: flex-end;
}
.pending-overview-item .store-text {
     display: flex;
 }
.pending-overview-item .store-value {
    display: flex;
    justify-content: flex-end;
}

/*---PENDING DETAIL CSS---*/

.pending-detail-item {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.pending-detail-item .text {
    display: flex;
}

