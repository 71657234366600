.custom-sidebar-class{
    width:60px;
    height:100%;
    background:black;

}
.custom-sidebar-class li{
   margin-bottom: 6px;
}

.custom-sidebar-class a{
    font-size: 22px;
    color: #ffffff;
    text-decoration: none;
}

.side-bar{
    float:right;
}
.sidebar-item{

    padding-bottom: 30px;
}
.sidebar-item i{
    color: white;
    font-size: 44px;
    margin:0 auto;
}

.sidebar-item a {
    display: block !important;
    text-align: center;
    color: white !important;
    margin:0 auto;
}

.hamburger-menu{
    width:40px;
    height: 30px;
}



#sidebarlogo {
    width: 100%; /* Need a specific value to work */
}

#sidebarfooter {
    bottom:0;
    left: 0;
    right: 0;
    width: 100%; /* Need a specific value to work */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.footer-links{
    padding-top: 10px;
    justify-content: center;
    display: flex;
}


.icon-container{
    width: 100%;
}

.sidebar-item.not-available {

    pointer-events: none;

/* for "disabled" effect */
    opacity: 0.5;
}


.footer-icon svg{
    width: 20px;
    height: 20px;
    fill: #000 !important;
}

ul {
    padding:0;
    list-style: none;
}


.sidebar-social-icons{

}

.social-icons {
    margin-top: 10px;
    list-style: none;
}
.social-icons li {
    vertical-align: top;
    display: inline;
    height: 100px;
}
.social-icons a {
    color: #fff;
    text-decoration: none;
}

.test{
    display: flex;
    height: 100vh;
}

nav {
    flex: 0 0 auto;
    background: black;
    color: black;
}
section{
    flex: 1 1 auto;
    
}

.sidebar-item svg{
    width: 50px;
    height: 50px;
    fill: #fff;
}

/*NEW CSS*/

.side-bar-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.side-bar-icons{
    height: 70%;
    display: flex;
    flex-direction: column;
}
.side-bar-footer {
    height: 30%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.footer-links{
    display: flex;
    flex-direction: column;
}
.footer-links a{
    font-size: 20px;
    padding-bottom: 10px;
}
#sidebarsocials{
    display: flex;

}
#sidebarsocials a{
font-size: 16px;
}



@media only screen and (max-width: 800px) {
    .custom-sidebar-class{
        width:60px;
        height:100%;
        background:black;
    }
    .sidebar-closed{
        width:60px;
    }
    .sidebar-item-hamburger{
        padding-left: 40px;
        padding-bottom: 30px;
        padding-right: 0px;
    }

    .sidebar-item svg{
        width: 40px;
        height: 40px;
        fill: #fff;
    }
    .sidebar-item i{
        color: white;
        font-size: 36px;
    }

    .sidebar-item a {
        display: block !important;
        text-align: center;
        color: white !important;
        font-size: 18px;
    }

    #sidebarlogo {
        width: 60px; /* Need a specific value to work */
    }

    #sidebarlogo-closed {
        width: 60px
    }

    #sidebarfooter {
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60px;
    }

    #sidebarfooter a{
        display: block;
    }
    #sidebarfooter #line {
        display: none;
    }
}

@media only screen and (max-width: 1025px)and (min-width: 800px) {
    .custom-sidebar-class{
        width:60px;
        height:100%;
        background:black;
    }
}
