.price-section {
    font-weight: 700;
}

.final-price {
    color: #a43737;
}

.old-price {
    text-decoration: line-through;
}

.catalog.price-section .old-price {
    margin-left: 15px;
}

.checkout.price-section {
    display: flex;
    flex-direction: column;
}