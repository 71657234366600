
/*
--------------STAGE ONE----------------
 */
.shipment-order-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.shipment-order-table-header {
    font-weight:700;
    margin-left: 15px; /*removes grid styling used by bootstrap*/
    margin-right: 15px; /*removes grid styling used by bootstrap*/
}

.shipment-order-table-header {
    font-size: 18px;
}

.shipment-order-table-item-wrapper{
    border: 1px solid rgba(172, 172, 172, 0.91);
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
    margin: 1%;
    padding: 2% 0 2% 0;
}
.shipment-order-table-item{
    display: flex;
    margin: 0 2% 0 2%;
}
/*removes grid styling used by bootstrap*/
/*flex and allow vertical centering of coloumns*/
.shipment-order-table-item.row{
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.shipment-order-table-item.row > div{
}
.shipment-order-table-item i {
    font-size:50px;
}

.shipment-order-table-item span {
    font-size:16px;
}

.shipment-order-table-item i {
    padding: 2%;
}
.shipment-order-table-item .item-image{
    
}
.shipment-order-table-item .item-info{
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.shipment-order-table-item .item-info .item-check {

}

.shipment-order-table-item .shipment-order-reason {
    width:100%;
    margin-bottom: 0;
}
.shipment-order-table-item .item-image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.shipment-order-actions-wrapper {
    padding-top:2%;
    padding-bottom: 3%;
}
.shipment-order-actions {
    display: flex;
    padding: 2%;
    justify-content: center;
}

/*vertically cerntering col and making it flex*/
.shipment-order-actions > div {
    display: flex;
    align-items: center;
}

.shipment-order-actions > div > * {
    margin: 0 auto; /*centering into div*/
    width:50%;
    align-items: center; /*vertically centering into coloumn*/
}


.shipment-order-button{
    justify-content: center;
    text-transform: uppercase;
    color: white;
    display: flex;
    border:0px;
    background-color:black;
    padding: 16px;
    border-radius: 0;
    position: relative;
}
.shipment-label-button{
    font-size: 14px;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    display: flex;
    margin:10px;
    align-items: center;
    background-color:black;
    padding: 16px;
    border-radius: 0;
    max-width: 250px;
    justify-content: space-evenly;
    position: relative;
}


.shipment-order-button:hover{
    background-color:black;
    border-radius: 0;
    opacity: 0.85;
}

.shipment-order-button.success{
    background-color:#87ad3e;
}
/*
--------------STAGE TWO----------------
 */
.dynamic-label-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
/* changes coloumns to center everything*/
.dynamic-label-row > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shipment-label-table {
    border:2px solid black;
    border-radius: 10px;
    min-width: 350px;
}
.shipment-label-table.red {
    border:2px solid #b91c00;
}
/*
will remove this as this is just remporary for orders.
 */
.shipment-label-table i{
    font-size: 30px;
}

.shipment-label-table-header {
    margin-left: 0;
    margin-right: 0;
    font-weight:700;
    background-color: black;
    color: white;
    align-items: center;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
}
.shipment-label-table-header.red {
    background-color: #b91c00;
}
.shipment-label-table-header.true{
    background-color: #99B564;
}

.shipment-label-table-item{
    padding: 2% 0 2% 0;
}
.shipment-label-table-item .row{
    margin: 0;
}
/*removes grid styling used by bootstrap*/
/*flex and allow vertical centering of coloumns*/
.shipment-label-table-item.row{
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.shipment-label-table-item.row > div{
    flex: 1 0 26%;
}

.shipment-label-table-header .parcel-label{
    font-size:16px;
}

.shipment-label-complete {
    color: white;
    background-color: #99B564;
    width: 100%;
    height:100%;
}

.shipment-label-complete >p {
    font-size: 29px;
    font-weight: 700;
}
.label-image{
    max-width: 160px;
    max-height: 200px;
    object-fit: cover;
}

/**
PARCEL SELECTION CSS
 */

.parcel-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.parcel-toggle .parcel-toggle-item {
    transition: background 300ms;
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: #969696;
    border-radius: 4px;
}
.parcel-toggle .parcel-toggle-item:hover {
    background-color: #5e5e5e;
}
.parcel-toggle .parcel-toggle-item.parcel-toggle-item--selected {
    background-color: #99B564;
}

.parcel-col.col-sm-12 {
    background: white;
    padding: 1%;
}

.parcel-toggle {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 2px;
}
