.tippy.in{
opacity: 1;
}
.form-group-lg .form-control{
    border-radius:0;
}
/*
REACT-SELECT minimum height to match inputs
 */
.css-1aya2g8{
    min-height: 46px !important;
}

.form-group-lg .form-control+.form-control-feedback, .input-group-lg+.form-control-feedback, .input-lg+.form-control-feedback {
    line-height: 45px !important;
}


/*
------------GLOBAL--------------
*/

.inline-input{
    display: flex;
    align-items: center;
}
.inline-input label{
    margin-right: 10px;
}
.inline-input .form-select{
    width:100%;
}
.inline-input input{
    width:100%;
}


/*
------------HELP TOOLTIP--------------
*/

.help-tooltip .tooltip-inner{
    padding: 7px 9px;
    max-width: 300px;
    background-color: #6198C1
}

.help-tooltip.right .tooltip-arrow{
    border-right-color:#6198C1;
}

.help-tooltip.bottom .tooltip-arrow{
    border-bottom-color:#6198C1;
}
/*
------------ERROR TOOLTIP--------------
*/

.error-tooltip.right .tooltip-arrow{
    border-right-color:#FF5D5D;
}

.error-tooltip.bottom .tooltip-arrow{
    border-bottom-color:#FF5D5D;
}

.error-tooltip .tooltip-inner{
    padding: 7px 9px;
    max-width: 300px;
    background-color: #FF5D5D
}

/*
------------SUCCESS TOOLTIP--------------
*/

.success-tooltip .tooltip-inner{
    padding: 7px 9px;
    max-width: 300px;
    background-color: #69cc07;
}

.success-tooltip.right .tooltip-arrow{
    border-right-color:#69cc07;
}


.success-tooltip.bottom .tooltip-arrow{
    border-bottom-color:#69cc07;
}

/*
------------SUCCESS TOOLTIP--------------
*/

.info-tooltip .tooltip-inner{
    padding: 7px 9px;
    max-width: 300px;
    background-color: #6198C1
}

.info-tooltip.right .tooltip-arrow{
    border-right-color:#6198C1;
}
.info-tooltip.right {
    margin-top: 15px;
}

.info-tooltip.bottom .tooltip-arrow{
    border-bottom-color:#6198C1;
}




/*
------------MULTI SELECT--------------
*/

.multi-select-wrap{
    width: 100%;
    height: 100%;
}

.multi-select-row{
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-left: 0;
}

.multi-select-column{
    display: inline-flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid black;
    background-color: black;
    color: white;
}

.first-multi {
    border: 0;
}

.multi-select-column-text{
    width: 100%;
}

