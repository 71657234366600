.accordion-panel.is-expanded {
    max-height: fit-content !important;
}

.wrapper {
    padding: 2% 5%;
}

/* Header */

.heading {
    display: flex;
    padding: 2% 0;
}

.left-heading, .right-heading {
    width: 50%;
}

.right-heading {
    text-align: right;
}

.create-user-button {
    text-align: center;
    padding: 2% ;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid black;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.create-user-button:hover {
    border: 1px solid white;
    background-color: black;
    color: white;
}

.create-user-button img {
    width: 15%;
}


.results-per-page{
    margin-top: 20px;
}

.results-per-page > *{
    color: #878280;
}

.results-per-page > a{
    text-decoration: underline;
}

.per-page-selected{
    color: #5c5c5c !important;
    font-weight: bold;
}

/* Accordion */

.react-accordion-with-header {
    box-shadow: none !important;
}

.applicant-accordion-header {
    width: 100%;
}

.accordion-header-wrapper {
    align-items: center;
    width: 100%;
}

.accordion-node {
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
    border-radius: 3px;
    margin-bottom: 2%;
}

.accordion-node.with-error {
    box-shadow: rgb(255, 0, 0) 0px 0px 0px 1px, rgb(255, 182, 182) 0px 1px 3px 0px
}


.applicant-accordion-header .left-accordion-header {
    padding: 3%;

}

.applicant-accordion-header.header-is-expanded {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
}

.applicant-accordion-header .left-accordion-header .store {
    width: 20%;
    float: left;
    text-align: left;
}

.applicant-accordion-header .left-accordion-header .name-position,
.applicant-accordion-header .left-accordion-header .status {
    width: 100%;
    float: left;
    text-align: left;
}

.applicant-accordion-header .left-accordion-header .store,
.applicant-accordion-header .left-accordion-header .status {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
}

.applicant-accordion-header .left-accordion-header .name-position .name{
    font-family: acumin-pro-condensed, sans-serif;
    font-style: italic;
    font-weight: 800;
    font-size: 16pt;
    text-transform: capitalize;
    width: 100%;
    float: left;
}

.applicant-accordion-header .left-accordion-header .name-position .role,
.applicant-accordion-header .left-accordion-header .status .trial-date {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    text-transform: capitalize;
    width: 100%;
    float: left;
}

.applicant-accordion-header .right-accordion-header {
    padding: 5% 0% 0% 0%;
    border-left: 1px solid rgba(63, 63, 68, 0.05);
}
.applicant-accordion-header .right-accordion-header .row {
    text-align: right;
    display: flex;
}
.applicant-accordion-header .right-accordion-header .arrow-col {
    display: flex;
    align-items: center;
}

.applicant-accordion-header .right-accordion-header .quarter{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 33.33%;
    float: left;
}


.applicant-accordion-header .right-accordion-header .quarter .attribute-heading {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    width: 100%;
    float: left;
}

.applicant-accordion-header .right-accordion-header .quarter .attribute-heading.grey {
    color: rgba(63, 63, 68, 0.05);
}

.applicant-accordion-header .right-accordion-header .quarter .attribute-message {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 8pt;
    text-transform: uppercase;
    width: 100%;
    float: left;
}

.applicant-accordion-header .right-accordion-header .attribute-heading img {
    width: 15%;
}



/* Dropdowns */

.submenu {
    padding: 2%;
    margin-bottom: 2%;
    clear: both;
}
.submenu-success{
    padding:5%;
}

.submenu-col {
    width:100%;
}

.submenu-heading {
    margin-top: 5px;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13pt;
    text-transform: uppercase;
    width: 100%;
    float: left;
    text-align: left;
}

.submenu .submenu-heading.day {
    text-transform: capitalize;
}

.submenu .left-menu {

    width: 100%;
    float: left;
    padding: 2%;
}
.submenu .downloads-menu{
    width: 100%;
    float: left;
    padding: 2%;
    display: flex;
    align-self: center;
}

.submenu .right-menu {
    width: 80%;
    float: right;
    padding: 3%;
}

.submenu .file-type {
    display: flex;
    align-self: center;
    text-align: center;
    padding: 2% 10%;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px dashed black;
    color: black;
    width: 50%;
    float: left;
    margin: 0 2%;
    border-radius: 0;
    line-height: 1.5;
}

.submenu .file-type .download-wrapper{
    padding-right: 10px;
    align-self: center;
}
.submenu .file-type .type{
    align-self: center;
}
.change-trial-button{
    float:right;
    width:100%;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 0;
    padding: 7% ;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
}

.change-trial-button:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
}
.submenu .approve-button {
    width: 46%;
    float: left;
    margin-right: 2%;
}

.submenu .approve-button:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
}

.submenu .pending-form .approve-button {
    background-color: #0a8dff;
    color: white;
    width: 100%;
    height: 70px;
    float: left;
    margin-right: 2%;
}

.submenu .pending-form .approve-button:hover {
    border: 1px solid black;
    background-color: #0a8dff;
    color: white;
}

.offer-button{
    margin-top: 20px;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;

    float: left;
    border-radius: 0;
}

.ref-check-button{
    margin-top: 3px;
    margin-left: 0px !important;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    width: fit-content;
    float: left;
    border-radius: 0;
}

.offer-refcheck{
    font-size: 18px;
}

.offer-header{
    margin-bottom: 5px;
    border-bottom: 1px solid black;
    font-size: 24px;
    padding: 0 0 2px 0;
}

.ref-check-button-subtitle{
    display: inline-block;
    font-size: 17px;
    font-style: italic;
}

.popup-content{
    width: 80vw !important;
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.popup-refcheck{

}

.popup-overlay{
    overflow: scroll;
}

.submenu .deny-button {
    margin-left: 2%;
    background-color: white;
    width: 46%;
    float: left;
    color: black;
}

.submenu .deny-button:hover {
    border: 1px solid white;
    background-color: black;
    color: white;
}


.submenu .file-status {
    text-align: center;
    padding: 2% 10%;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    width: 45%;
    float: left;
    margin: 0 2%;
    border-radius: 0;
    line-height: 1.5;
}

.submenu .file-type.approved {
    border: 1px dashed rgba(105, 204, 7, 1);

}

.submenu .file-type.denied {
    border: 1px dashed rgba(255, 93, 93, 1);
}

.submenu .button-wrapper {
    width: 100%;
    float: left;
}

.submenu .checkbox {
    width: 100%;
    float: left;
}

.submenu .checkbox label, .submenu .radio {
    float: left;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12pt;
    text-transform: uppercase;
    -webkit-appearance: none;
    padding: 0.5% 1%;
    margin-top: 10px;
}

.submenu .checkbox {
    margin: 0 0 0 0;
}
.submenu .btn-group {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    margin: 0 0 0 0;
    width: 100%;
    margin-bottom: 1%;
}

.upload-wrapper {
    width: 100%;
    float: left;
    padding: 2% 0;
}

.upload-list {
    width: 100%;
}

.upload-item {
    padding: 1%;
    float: left;
    width: 33.333%;
}

.submenu .pending-form {
    text-align: left;
    width: 100%;
}

.submenu .pending-form .right-form .deny-button,
.submenu .pending-form .right-form .approve-button{
    width: 80%;
    padding: 5% 10%;
    margin: 50% 10%;
}

.right-form .approve-button.medium {
    margin: 25% 10%;
}

.submenu .pending-form .left-form .control-label {
    width: 30%;
    padding-top: 1.5%;
    float: left;
    text-align: left;
}

.submenu .pending-form .left-form input {
    width: 65%;
}
.submenu .pending-form .left-form .checkbox input {
    width: inherit;
}

.submenu .pending-form .left-form .half-width {
    width: 100%;
    float: left;
    margin-top: 1%;
}

.submenu .pending-form .left-form .half-width .control-label {
    width: 25%;
    padding-top: 1.5%;
    float: left;
}

.submenu .pending-form .left-form .half-width input {
    width: 75%;
    float: left;
    margin-right: 1%;
}

.submenu .pending-form .left-form .half-width .select {
    clear: both;
    width: 75%;
}

.submenu form.full-width {
    width: 100%;
    margin: 5% 25%;
}

.submenu .successful-form {
    width: 100%;
    margin-bottom: 5%;
}

.submenu .successful-form .left-form {
    width: 60%;
    float: left;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
}

.submenu .successful-form .right-form {
    width: 40%;
    float: right;
}

.submenu .successful-form .right-form .deny-button{
    width: 80%;
    padding: 5% 10%;
    margin: 10% 10%;
}

.submenu .successful-form .left-form .form-group {
    margin-top: 10%;
}

.submenu .successful-form .left-form .control-label {
    width: 30%;
    padding-top: 1.5%;
    float: left;
    text-align: left;
}

.submenu .successful-form .left-form input {
    width: 50%;
}

.availability-day {
    text-align: left;
}

.availability-day .availability-day-info {
    padding-left: 2%;
}

.work-notes-submenu {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    padding-bottom: 1%;
    text-align: left;
}

.submenu .status-wrapper {
    text-transform: uppercase;
    font-size: 14pt;
    align-self: center;
    text-align: left;
}
.submenu .download-wrapper {
    text-align: left;
    cursor: pointer;
}

.search-filter-wrapper {
    width: 100%;
    text-align: left;
    margin-bottom: 2%;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
}

.search-wrapper {
    width: 100%;
}

.search-wrapper label {
    float:left;
    font-size: 14pt;
}

.search-wrapper .search {

    padding: 6%;
    border: 1px solid rgba(63, 63, 68, 0.05);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-bottom: 3%;
}

.inputWithIcon input[type="text"] {
    padding-left: 40px;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon  svg {
    position: absolute;
    left: 0;
    top: 35px;
    width:40px;
    height:40px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
}

.inputWithIcon .icon {
    position: absolute;
    left: 0;
    top: 40px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + .icon {
    color: dodgerBlue;
}


.search-filter-wrapper .search-wrapper {
    width: 100%;
    padding-left: 3%;
}

.search-filter-wrapper .search-wrapper .search {
    width: 100%;
    padding: 5%;
    border: 1px solid rgba(63, 63, 68, 0.05);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-bottom: 3%;
}

.filter-header-wrapper {
    align-items: center;
    display: flex;
    border-left: 1px solid rgba(63, 63, 68, 0.05);
}
.filter-header-wrapper .attr-view{
    text-align:right;
}
.filter-header-wrapper .attr-view span h2{
    text-align:right;
}


.search-filter-wrapper .filter-wrapper {
    width: 100%;
    padding: 1%;
    text-align: right;
}

.search-filter-wrapper .filter-wrapper .filter-label {
    margin-bottom: 3%;
}

.search-filter-wrapper .filter-wrapper .left-side {
    padding: 3%;
    border-right: 1px solid rgba(63, 63, 68, 0.05);
    text-align: left;
}

.search-filter-wrapper .filter-wrapper .right-side {
    padding: 3%;
    text-align: left;
}

.search-filter-wrapper .filter-wrapper .left-side .dropdown{
    width: 100%;
    float: left;
}

.save-settings-container{
    width: 100%;
}

.save-settings-button{
    width:fit-content;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13pt;
    text-transform: uppercase;
    border: 1px solid black;
    background-color: white;
    color: black;
    cursor: pointer;
    align-self: center;
    border-radius: 0;
    padding: 8px 1%;
    margin: 5px 3%;
}

.save-settings-button:hover{
    background-color: #f3f3f3;
}

.save-settings-button:active{
    background-color: #ececec;
}
.save-settings-button:focus{
    outline: none;
}

.clear-settings-button{
    width:fit-content;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12pt;
    text-transform: uppercase;
    color: black;
    background-color: white;
    border: 1px solid white;
    outline: none;
    cursor: pointer;
    align-self: center;
    border-radius: 0;
    padding: 2px 1%;
    margin: 2px 3%;
    text-decoration: underline;
}

.clear-settings-button:active{
    /*border: 1px solid black;*/
    color: cornflowerblue;
}

.clear-settings-button:focus{
    outline: none;
}

.accordion-header-wrapper.applicants {
    padding: 2%;
}

.accordion-header-wrapper.search-header  {
    flex-direction: row;
    padding: 2%;
}
.search-header .filter-header-wrapper .quarter {
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
}

.accordion-header-wrapper .wide-side {
    float: left;
}
.accordion-header-wrapper .row{
    width:100%;
    display: flex;
    align-items: center;
}


.accordion-header-wrapper .wide-side .text {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: italic;
    font-weight: 800;
    font-size: 16pt;
    text-transform: capitalize;
    width: 100%;
    float: left;
    text-align: left;
    padding-top: 1.5%;
}

.accordion-header-wrapper .narrow-side {
    float: right;
}
.viewMore{
    display: none;
}
.reference-wrapper {
    width: 50%;
    float: left;
}

.inputfile-2.full-width + label {
    width: 100%;
}

.spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

.reference-wrapper .select {
    width: 75%;
}

.contact-heading {
    text-align: left;
    width: 100%;
    float: left;
    padding: 0 2%;
    font-weight: bold;
}

.contact-info {
    text-align: left;
    float: left;
    padding: 0 2%;
}

.right-accordion-header .mobile-attr-view{
    display:none
}

@media all and (max-width: 1300px) {

    .applicant-accordion-header .left-accordion-header .name-position .name{
        font-size: 14pt;
    }

    .applicant-accordion-header .left-accordion-header .name-position .role,
    .applicant-accordion-header .left-accordion-header .status .trial-date {
        font-size: 10pt;
    }

    .applicant-accordion-header .left-accordion-header .status {
        font-size: 12pt;
    }
    .applicant-accordion-header .right-accordion-header .quarter .attribute-heading{
        font-size:  11pt !important;
    }
}
@media all and (max-width:768px){
    .right-accordion-header .arrow {
        width: 50%;
        margin: 0 auto;
    }
    .applicant-accordion-header .left-accordion-header{
        border: none;
    }
}
@media all and (max-width: 568px) {

    .right-accordion-header{
        margin-left: -10px;
        padding-right: 10px;
    }

    .right-accordion-header .mobile-attr-view{
        display:block
    }

    .right-accordion-header  .attr-view{
        display: none;
    }

    .submenu .file-type{
        padding: 0;
    }

    .right-accordion-header .arrow {
        width: 50%;
        margin: 0 auto;

    }

    .right-accordion-header .quarter{
        width:50%;
    }

    .accordion-header-wrapper .row{
        margin-right: 0px;
        margin-left: 0px;
        display: block;
    }
    .applicant-accordion-header .right-accordion-header .quarter .attribute-heading{
        font-size:  15px;
    }
    .applicant-accordion-header .left-accordion-header{
        border:none;
    }


    .heading {
        display: block;
    }

    .heading .left-heading, .heading .right-heading {
        width: 100%;
        text-align: center;
        padding: 1% 0;
    }
    .applicant-accordion-header .right-accordion-header .quarter {
        padding-top: 2px;
        padding-bottom: 2px;
        width: 50%;
        float: left;
    }

    .accordion-header-wrapper .left-accordion-header {
        width: 100%;
    }

    .accordion-header-wrapper .left-accordion-header .store,
    .accordion-header-wrapper .left-accordion-header .name-position
    {
        text-align: right;
    }
    .accordion-header-wrapper .left-accordion-header .status {
        text-align: center;
        padding-top: 20px;
    }


    .accordion-header-wrapper .right-accordion-header {
        width: 100%;
    }
    .applicant-accordion-header .right-accordion-header .row{
        display: inline;
    }

    .accordion-header-wrapper .wide-side {
        width: 75%;
    }

    .accordion-header-wrapper .narrow-side {
        width: 25%;
    }

    .accordion-panel .checkbox.checkbox label {
        padding-left: 7%;
    }

    .pending-form .offer-details {
        float:unset;
    }

    .submenu .pending-form .left-form {
        border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    }

    .submenu .pending-form .right-form .approve-button.very-long,
    .submenu .pending-form .right-form .approve-button.medium {
        margin: 10%;
    }

    .upload-item {
        width: 100%;
        padding: 1%;
        text-align: center;
    }

    .inputfile-2 + label {
        width: 100%;
    }

    .submenu .pending-form .left-form .half-width input {
        width: 100%;
    }

    .submenu .pending-form .left-form .half-width .select {
        clear: both;
        width: 100%;
    }

    .submenu.uploads .approve-button {
        margin: 0 2%;
    }

    .submenu.uploads .left-menu, .submenu.uploads .right-menu {
        width: 100%;
    }

    .submenu.uploads .right-menu .file-status {
        width: 100%;
    }

    .search-filter-wrapper .search-wrapper {
        width: 100%;
    }

    .search-filter-wrapper .search {
        width: 100%;
    }

    .search-filter-wrapper .filter-wrapper {
        width: 100%;
    }

    .search-filter-wrapper .filter-wrapper .left-side {
        width: 100%;
    }

    .search-filter-wrapper .filter-wrapper .right-side {
        width: 100%;
    }

    .accordion-header-wrapper .wide-side .text {
        padding-top: 3%;
    }

    .applicant-accordion-header .right-accordion-header .quarter .attribute-heading {
        padding-top: 10%;
    }

    .reference-wrapper {
        width: 100%;
    }

    .reference-wrapper .select {
        width: 100%;
    }

    .contact-info {
        width: 100%;
    }

    .toggle-arrow {
        width: 30px !important;
    }


    .submenu .approve-button{
        font-size:14px;
        white-space: inherit;
    }

}


/**
NEW ACCORDION CSS
*/
.accordion-panel.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.5s ease-in;
}
.accordion-panel {
    animation: fadein 0.35s;
}
.accordion-header-wrapper{
    display: flex !important;
}




.toggle-arrow{
    width:40px;
}


[aria-selected='true'] .toggle-arrow {
    transition: all .35s;
    transform: rotate(180deg);

}

[aria-selected='false'] .toggle-arrow {
    transition: all .35s;
    transform: rotate(0deg);

}


@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes slideIn {
    0% { height: 0px; opacity: 0;}
    100% { height: 100px;   opacity: 1;}
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(180deg); }
}

/*.applicant-accordion-header:hover .toggle-arrow {*/
/*animation-name: move-down;*/
/*animation-duration: 1.5s;*/
/*}*/

/*.applicant-accordion-header[aria-expanded='true']:hover .toggle-arrow {*/
/*animation-name: move-up;*/
/*animation-duration: 1.5s;*/
/*}*/

.applicant-accordion-header:focus {cursor:pointer;outline:0;}
.applicant-accordion-header:hover {cursor:pointer;}




.loading-spinner {
    /* changing --size & --circle-size will adjust size of the spinner */
    --size: 50px;
    --circle-size: 3px;
    --vertical-horizontal-offset: calc(5 * var(--circle-size));
    --diagonal-offset: calc(3.5 * var(--circle-size));
    /* changing --color will adjust the color of the spinner */
    --color: 255, 255, 255;
    transition: transform 1s ease-in-out;
    min-height: var(--size);
    min-width: var(--size);
    position: relative;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.loading-spinner-blue {
    --color: 21, 101, 192 !important;
}

.loading-spinner::after {
    display: block;
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    height: var(--circle-size);
    width: var(--circle-size);
    animation: rotate 1s steps(8) infinite;
    box-shadow:
        /* top circle */
            0 calc(-1 * var(--vertical-horizontal-offset)) 0 var(--circle-size) rgba(var(--color), 1),
                /* top-right circle */
            var(--diagonal-offset) calc(-1 * var(--diagonal-offset)) 0 var(--circle-size) rgba(var(--color), 0.7),
                /* right circle */
            var(--vertical-horizontal-offset) 0 0 var(--circle-size) rgba(var(--color), 0.5),
                /* bottom-right circle */
            var(--diagonal-offset) var(--diagonal-offset) 0 var(--circle-size) rgba(var(--color), 0.3),
                /* bottom circle */
            0 var(--vertical-horizontal-offset) 0 var(--circle-size) rgba(var(--color), 0.2),
                /* bottom-left circle */
            calc(-1 * var(--diagonal-offset)) var(--diagonal-offset) 0 var(--circle-size) rgba(var(--color), 0.2),
                /* left circle */
            calc(-1 * var(--vertical-horizontal-offset)) 0 0 var(--circle-size) rgba(var(--color), 0.15),
                /* top-left circle */
            calc(-1 * var(--diagonal-offset)) calc(-1 * var(--diagonal-offset)) 0 var(--circle-size) rgba(var(--color), 0.4);
}