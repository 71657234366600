.search-filter-wrapper .row{
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.shipping-wrapper {
    padding: 1% 3%
}
.shipping-wrapper-login{
    padding: 20% 3%
}

.shipping-search {
    width:100%;
    padding: 6%;
    border: 1px solid rgba(63, 63, 68, 0.05);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-top: 3%;
    margin-bottom: 3%;
}
.shipment-search-checkbox {

}
.store-login-wrapper {
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.store-login-wrapper span{
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.store-login-wrapper .pending-notification {
    background-color: #c02d28;
    position:fixed;
    top:0;
    left:50%;
    width:80px;
    height:100px;
    z-index:100;
}

.all-shipment-button, button.all-shipment-button-toggle.btn {
     text-align: center;
     padding: 2%;
     margin: 1%;
     font-family: acumin-pro-condensed, sans-serif;
     font-style: normal;
     font-weight: 600;
     font-size: 14pt;
     text-transform: uppercase;
     background-color: white;
     color: black;
     cursor: pointer;
     border: 1px solid black;
     -webkit-transition-duration: 0.4s;
     -o-transition-duration: 0.4s;
     transition-duration: 0.4s;
 }
button.all-shipment-button-toggle.btn {
    border-radius: 0;
    margin-top: 0;
    padding: 2.9%;
}
.all-shipment-button-list.dropdown-item {
    padding: 12px 28px;
    text-transform: uppercase;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
}
.all-shipment-button:hover:not([disabled]) {
    color:white;
    background-color: black;
}

.manifest-wrapper {
display: flex;
}
.shipping-buttons{
display: flex;

 }
.download-shipment-button {
    text-align: center;
    padding: 2%;
    margin: 1%;
    max-width: 100px;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    background-color: white;
    color: black;
    cursor: pointer;
    border: 1px solid black;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
}