.feed > .row{
    margin-bottom: 2%;
}

.feed .campaign {

}

.feed .campaign img{
    width:100%;
    height: 150px;
    object-fit: cover;
    object-position: 100% 30%;
}

.feed .black-box {
    width:100%;
    background-color: rgba(0, 0, 0, 0.91);
}

.feed .black-box img {
    width: 90%;
    margin-bottom:2%;
}

.feed .black-box h2, .feed .black-box h3 {
    color: white;
    font-style: normal;
    text-align: center;
    font-weight: 600;
}
/*centering news feed icon with text*/
.news-feed > div {
    display: flex;
    align-items: center;
    margin-bottom: 4%;
}

.news-feed svg {
    width:30px;
}

.news-feed .message {
    font-size: 15px;
    text-align: left;
    float: left;
}

.news-feed .from {
    text-align: right;
    float: right;
    font-style: italic;
}

.campaign .message {
    font-size: 15px;
}
@media all and (max-width: 568px) {
    .feed .black-box {
    height: 100%;
    }

    .news-feed .message {
        font-size: 15px;
        text-align: justify;
        float: left;
    }

    .feed .content > div {
        margin-bottom: 10%;
    }
}