body {
  font-family: acumin-pro-condensed, sans-serif;
}

body h1,h2,h3,h4,h5 {
  font-family: acumin-pro-condensed, sans-serif;
}

.App {
  text-align: center;
  width:100%;
  height:100%;
}

.app-wrapper.logged-in{
  display: flex;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.react-confirm-alert-button-group > button{
  border-radius: 0px !important;
}

.heading-text {
  font-family: acumin-pro-condensed, sans-serif;
  font-style: italic;
  font-weight: 800;
  text-align: left;
  padding-bottom:1%;
}

.medium-heading-text{
  font-family: acumin-pro-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16pt;
  text-align: left;
}

.small-heading-text {
  font-family: acumin-pro-condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12pt;
  text-align: left;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/*Safari Only Styling - this works even with error.*/
::-webkit-full-page-media, _:future, :root .task-wrapper {
  display: inline !important;
}
::-webkit-full-page-media, _:future, :root .task-type {
  top:15px;
  margin-bottom: 10px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


@media only screen
and (min-device-width : 200px)
and (max-device-width : 800px)
{
  .tooltip
  {
   /* d isplay: none !important;*/
  }
}
/*POS Specific CSS*/
@media only screen
and (min-device-width : 800px)
and (max-device-width : 1025px)
{
}


.large-button {
  width: 250px;
  height:70px;
  text-align: center;
  padding: 2%;
  font-family: acumin-pro-condensed, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14pt;
  text-transform: uppercase;
  border: 1px solid black;
  background: black;
  color: white;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.small-button {
  width: 100%;
  height:50px;
  text-align: center;
  padding: 2%;
  font-family: acumin-pro-condensed, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14pt;
  text-transform: uppercase;
  border: 1px solid black;
  background: black;
  color: white;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}