/* import the necessary Bootstrap files */
@import "~bootstrap/scss/bootstrap";
$uniGreen:#87ad3e;


/**
UNI bootstrap Button styling
 */
.uni.btn{
  border-radius: 0;
  min-height: 3rem;
}

.uni.small{
  border-radius: 0;
  min-height: 2rem;
}

.uni.btn-success {
  color: #fff;
  @include button-variant($uniGreen, #fff, #fff, lighten($uniGreen,5%), lighten($uniGreen, 10%), #fff);
}

.uni.btn-outline-btn-success {
  @include button-outline-variant($uniGreen, #222222, lighten($uniGreen,5%), $uniGreen);
}

/**
UNI bootstrap Input styling
 */
input.uni{
  border-radius: 0;
  min-height: 2.5rem;

}
.form-label{
  text-align: left;
  font-weight: 700;
  margin-bottom:0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}