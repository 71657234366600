.Signup{
    height:100%;
    width:100%;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/backgrounds/background.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 100%;
}

.Signup form {
    margin: 0 auto; max-width: 320px;
}
.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.LoginLogo{
    margin-bottom: 2%;
}

.ChangePassword{
    margin-left:0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.show-help{
    display:block;
    text-align: right;
}

.hide-help{
    display:none;
}

.privacy-info{
    color: white;
    font-size:14px;
}


.Signup form span.help-block {
    text-align: center;
    color: #ab1414 !important;
}

.ChangePassword form span.help-block {
    text-align: center;
    color: #ffffff !important;
}

.Login{
    color:white;
    font-size:1.2em;
}

.login label.control-label {
    color:white;
}
.login .form-group-lg .form-control{
    border-radius: 0;
}
.loginButton span {
    line-height: 1em;
}

.loginButton{
    margin:10px 0px 10px 0px;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    display: flex;
    border:0px;
    background-color:black;
    padding: 16px;
    border-radius: 0;
    position: relative;
}
.loginButton:hover{
    color: white;
    background-color:black;
    border-radius: 0;
    opacity: 0.85;
}

@media all and (max-width:480px){
    .Signup{
        background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../../images/backgrounds/background-mobile.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .Login{
        padding: 15% 0;

    }
    .login-logo{
        width:100%;
    }
    .LoginLogo{
        margin-bottom: 80px;
    }
    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
    .Login{
        color:white;
        font-size:1.2em;
    }
}

.ChangePassword{
    margin: 0 auto;
    padding-bottom: 10%;
    display: flex;
    align-items: center;
}
.ChangePassword form{
    max-width: 320px;
    margin: 0 auto;

}

