#App{
     margin-left: 80px;
     transition: margin-left .5s;
}
#App-store{
    margin-left: 0px;
}
.app-open-nav {
    margin-left: 120px !important;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    background-color: #000;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}
.fixed-sidenav {
    height: 100%;
    width: 80px;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #000;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.open-nav {
    width:120px;

}
#sidenav-overlay{
    width: 0;
    background: black;
    opacity: 0.6;
    height: 100%;
    position: fixed;
    z-index: 190;
    overflow-x: hidden;
}

#sidenavlogo {
    width:70px;
}


#sidebarfooter {

    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
}

#sidebarfooter a{
    display: block;
}
#sidebarfooter #line {
    display: none;
}

@media only screen and (max-width: 767px){
    #App {
        margin-left: 60px;
    }
    .fixed-sidenav {
        width:60px;
    }
    .open-nav {
        width:80px;
    }
    .app-open-nav {
        margin-left: 80px !important;
    }


    #sidenavlogo {
        width:60px;
    }

}