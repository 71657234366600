.list-group-item::before {
    content: none;
}

.list-group-item {
    display: flex;
    margin-bottom: 2%;
    border: none;
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
    border-radius: 3px;
}

.list .task-type {
    float: left;
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15pt;

    color: rgba(0,0,0, 0.65);
}

.list .task-name {
    float: left;
    text-align: left;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15pt;
    padding: 2%;
}

.list .task-action {
    float: left;


}

.list .task-action .form-group {
    margin-bottom: 0;
}

.list .task-confirm {
    text-align: center;
    padding: 5% 10%;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    float: left;

    border-radius: 0;
}

.list .task-wrapper {
    width: 100%;
}

.task-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin-left: 0;
}
.task-wrapper .row {
    align-self: center;
}

.task-wrapper .task-type, .task-action, .task-name{
    flex-grow: 2;
    align-self: center;
}

.search {
    width: 40%;
    padding: 2%;
    border: 1px solid rgba(63, 63, 68, 0.09);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-bottom: 3%;
    float:right;
}
.task-wrapper .button-wrapper{
    width:100%;
    height:100%;
    display: flex;
    align-self: center;
}
.button-wrapper .small-button {
    font-size: 12pt;
    margin-left: 4%;
    border-radius: 0px;
    height:40px;
    align-self: center;
}



@media all and (max-width: 768px) {
    .list .task-name, .list .task-action {
        width: 100%;
    }
}