.area {
    margin: 5% 5% auto;
    width: 80%;
    height: 500px;
    border-radius: 2.15rem;
    background: #f8f8f8;
}



.contract-heading-text{
    font-family: acumin-pro-condensed, sans-serif;
    font-style: italic;
    font-weight: 800;
    text-align: left;

}
.contract-sub-heading-text{
    padding-bottom: 2px !important;
    text-align: center;
}

.area .content {
    padding-top:10px;
    text-align: left;
    padding-left:20px;
    padding-right: 20px;
}

.area .content h2 {
    text-align: center;
}

.contract-logo{
    display:block;
    margin:auto;
    width: 50%;
    padding-bottom: 5px;
}
p{
    font-size: 18px;
}

.offer-container{
    width:100%;
    align-content: left;
}

.terms-conditions-container p{
    font-size: 15px;
}


.position-description-container p{
    font-size: 14px;
}

.quick-description-container p{
    line-height:0.6;
    font-weight: 800;
}
.quick-description-container span{
    font-weight: 400;
}


.position-description-container table {
    border-collapse: collapse;
}

.position-description-container table, td, th {
    border: 1px solid black;
}

.welcome-para{
    text-align: left;
    padding-top:50px ;
    margin: 0 5% auto;
    width: 80%;
    padding-bottom: 40px;
}

.position-description-head-para{
    text-align: left;
    padding-top:50px ;
    margin: 0 5% auto;
    width: 80%;
}

.confirm-para{
    padding-top:50px ;
    margin: 0 5% auto;
    width: 80%;
    padding-bottom: 40px;
}
.agreement-area{
    width:80%;
    margin: 0% 5% auto;
    text-align: left;

}
.agreement-area p{
    font-size: 14px;
    text-align: left;
}

ol {
    counter-reset: item;
    -webkit-padding-start: 0px !important;
}
ol ol {
    -webkit-padding-start: 25px !important;
}
li{
    display: block;
    list-style:none;
    font-size:14px;
}
.area li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    font-weight: 500;

}

.inline-divs{
    display: inline;
}

.offer-buttons{
    width:80%;
    display: flex;
    margin: 5% 5% auto;
    justify-content: space-between;
    padding-bottom: 60px;
}

.accept-offer-button{
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    width: 250px;
    float: right;
    border-radius: 0;
    float: right;
    order: 2;
}

.reject-offer-button{
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    color: black;
    cursor: pointer;
    width: 250px;
    float: right;
    border-radius: 0;
    float: right;
    order: 2;
    background-color: #dddddd;
}

.accept{
    height: 80px;
}

.reject-offer-button{
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    color: black;
    cursor: pointer;
    width: 250px;
    float: right;
    border-radius: 0;
    float: right;
    order: 2;
    background-color: #dddddd;
}

.accept{
    height: 80px;
}

.download-contract-icon{
    margin: 0% 5% auto;
    width: 80%;
    text-align: right;
}

@media only screen and (max-width: 500px) {

    .offer-container{
        width: 95%;
    }
    .welcome-para{
        width: 100%;
    }
    .confirm-para{
        width: 100%;
    }
    .agreement-area{
        width:100%;
    }
    .area {
        width: 100%;
    }
    .offer-buttons{
        width:100%;
    }
    .kSIiDU{
        padding-top: 200%;
    }
}

/*PDF CSS*/
.generatepdf{
    padding-top: 20px;
    padding-left: 18px;
    width:760px;
    text-align: left;
}

.generatepdf h2{
    text-align: center;

}

.generatepdf footer{
    text-align: center;
}
.hiddenform{
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.kSIiDU{
    padding-top: 150%;
}