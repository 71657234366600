.privacy-container{
    margin:2%;
    text-align: left;
}

.privacy-heading-text{
    font-family: acumin-pro-condensed, sans-serif;
    font-style: italic;
    font-weight: 800;
    text-align: left;
}

.privacy-container p{
   font-size: 14px;
}

.privacy-container ol {
    counter-reset: item;
    -webkit-padding-start: 25px !important;
}

li{
    display: block;
    list-style:none;
    font-size:14px;
}