@import "~bootstrap/scss/bootstrap";


/**
Global STD variables
 */
$primary-blue:#0d6efd;
$uni-green:#87ad3e;
$secondary-grey:#efefef;
$row-buffer-small:0.5rem;

/**
Global STD classes
 */

.center-y {
  display: flex;
  justify-content: center;
  flex-direction:column;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-login-view {
  padding-top:20%
}

.store-order-view {
  max-height : 100vh;
  overflow: hidden;
  text-align: left;
  margin: 1rem 0 1rem 1rem;
  .product-grid-view{
    overflow-x:hidden !important;
    max-height: 100vh;
    padding-right: 0.5rem; //fix scrollbar overflow
    height: calc(100vh - 100px);
    ::-webkit-scrollbar {
      display: none;
    }
    .product-card-view{
      .out-of-stock-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 999;
      }

      .card-img {
        object-fit: cover;
        text-align: center;
      }
      .loading-img{
        filter: blur(3px);
      }
      .card-body{
        padding: 1rem 0 0;

        span {
          font-size: 18px;
        }
      }

    }

  }

  .checkout-view {
    .checkout-progress-bar{
      .bg-custom-success {
        background-color:$uni-green;
      }
      font-weight:600;
      .selected-font{
        color: $uni-green
      }
    }
    min-height: calc(100vh - 4rem);
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .cart-item-view{
      max-height: 100vh;
      padding:1rem 2rem 0 1rem;
      .card-img {
        object-fit: cover;
        text-align: center;
      }
      .card-body{
        padding: 0;
      }
    }
      .customer-details-accordion{
        padding: 0;
        display: flex;
        flex-direction: column-reverse;

        .accordion-collapse{
          background-color:$secondary-grey;
        }
        .accordion-button{
          color: #0f0f0f;
          &:not(.collapsed) {
            background-color:$secondary-grey;
            box-shadow: inset 0 0 0;
          }
        }
        .accordion-body {
          min-height: 200px;
        }
      }
    .delivery-details-view {
      .btn-toolbar{
        flex-direction: column;
        justify-content: center;

        .btn-group{
          margin: 1rem 0 1rem 0;
        }

        .price{
          font-weight: 700;
        }
      }
    }

    .checkout-spinner {
      display: flex;
      justify-content: center;
      i {
        font-size:3rem;
      }
    }
  }

  .order-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;

  }
}
.confirm-details-view {
  .modal-dialog {
    max-width:65vw;
  }
  .cart-item-view{
    padding:1rem 0 0 0;
  }
  .confirm-customer-details {
    .row {
      margin-top: $row-buffer-small;
    }
    .btn {
      margin-top:1rem;
    }
  }
  .confirm-order-details{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    .confirm-total{
      text-align: end;
      margin-top: $row-buffer-small;
    }
  }


}

#address-results{
  #ui-id-1{
    top:30px !important
  }
}