.signup{
  height:100%;
  width:100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/backgrounds/background.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100%;


  .login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    min-height: 100%;

    .logo{
      padding:2rem;
    }
    form {
      width: 100%;
    }
  }
}

.welcome {
  .update-details-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5rem 0 0 1rem;
    text-align: center;
    min-height: 100%;
  }
}