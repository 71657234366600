
@media all and (min-width: 480px) {
    .Add form {
        margin: 0 auto;
        max-width: 520px;
    }
}
.Add form span.help-block
.side-by-side{
    display:inline-block;
}

.left {
    float: left;
    width: 100%;
}

.info-icon{
    width: 25px;
    height: 25px;
    margin-top:7px;
    float: right;
}
.info-icon { transition: all .2s ease-in-out; }
.info-icon:hover { transform: scale(1.6); }

.trial-time{
    width:100% !important;
    margin-left:0 !important;
}

.trial-time .ant-time-picker-input {
    height:46px;
}

.form-heading{
    font-family: acumin-pro-condensed, sans-serif;
}

.add-form .help-block {
    font-size: 14px ;
}


.show-help{
    display:block;
    text-align: right;
}

.hide-help{
    display:none;
}

.store-select .form-select :last-child {
    z-index:100;
}
.success-sub-heading-text{
    font-weight: 800;
    font-size: 16pt;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 5px;
    color: #69cc07;
}
.fail-sub-heading-text{
    font-weight: 800;
    font-size: 16pt;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 5px;
    color: #ff5d5d;
}

.small-message {
    padding-bottom: 2%;
}

.add-user-button {

}

.add-user-button:hover {
    background: white;
}
.add-user-button[disabled]:hover {
    background: black;
}

@media all and (max-width: 480px) {

}

@media screen and (max-width: 1025px) and (min-width: 800px){
    h1,h2,h3 {

    }

}