.reporting-icon{
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reporting-wrapper{
    height: 100vh;
    display: flex;
    align-items: center;
}
.reporting-wrapper > div{
    display: flex;
    justify-content: center;
}
