.page-div{
    padding: 2% 5%;

}

.sub-heading-text {
    font-family: acumin-pro-condensed, sans-serif;
    font-style: italic;
    font-weight: 800;
    font-size: 16pt;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 5px;

}

.form-control{
    display: block;
    width: 100%;
    padding: .775rem .95rem;
    font-size: 1.3rem;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.7px solid #ced4da;
    border-radius: .15rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.date-picker{
    padding: .670rem .95rem !important;
}

select.form-control{
    height: calc(3.25rem + 2px) !important;
    text-align-last:left;

}

.file-upload{

}


    /*breadcrumbs that I wi*/
.Bread-menu{
    display: inline-block;
}

.breadcrumb{
    background-color: white;

}

.form-container{
    padding-top: 2%;
    float:left;
    width: 95% !important;
    text-align: left;
    display: inline-block;
}
.emergency-contacts{
    display: flex;
}

.contact-one{

}
.contact-two{
}



/*AVAILABILITY CONTAINER STYLES*/
.time-picker{

    font-size: 1.2rem;
    float:left;
    text-align: left;
    width:100%;
}


.time-picker .label-switch label{
    vertical-align: middle;
}

.time-picker .label-switch{
    align-items: center;
    display: flex;
    height: 50px;
    width:300px;

}
.time-picker .label-switch .day{
    text-transform: capitalize;
    vertical-align: middle;
    float: left;
    order: 2;
}

.time-picker .label-switch label{
    vertical-align: middle;
    float: left;
    order: 1;
}
.time-picker .label-switch .MuiFormControlLabel-root-1{
    order: 1

}
.time-picker .label-switch .day-specific{
    margin-left: auto;
    order:3;
}

.time-picker{
    display: flex;
}


.day-specific label input {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.ant-time-picker {
    margin-left:20px;
}

.ant-time-picker .anticon{
    display: none;
}

.ant-time-picker-icon{
    display:none;
}

.small-remove-time{
    width: 40px;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    text-transform: uppercase;
    border: 0px solid black;
}
.small-time:hover {
    border: 1px solid white;
    background-color: #333333;
    color: white;
}

.small-add-time{
    margin-left: 15px;
    height:30px;
    width:100px;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10pt;
    text-transform: uppercase;
    border: 0px solid black;
}


.time-container {

    display: flex;
    padding-top: 7px;
}

.time-container button {
    align-items: flex-end;
}
.time-container .row{
    display: flex;
    padding: 2px;


}
.time-container .row div{

}

.upload-file-icon {
    margin-right: -25px !important;
}


.upload-file-icon i{
    color: black;
    font-size: 44px;
}

.inputfile {
    top: 0;
    width: 100%;
    height: 40px;
    opacity: 0;
    position:absolute;
    cursor: pointer;
}

.inputfileName{
    color: #000084;;
}

.selectFileButton{
    margin-bottom: 10px !important;
}

.upload-item .file-container{
    position: relative;
    width: 80%;
}
.inputfile + label {
    max-width: 100%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick API use */
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.inputfile-2 + label {
    color: #d3394c;
    border: 2px solid currentColor;
}

.inputfile-2:focus + label,
.inputfile-2.has-focus + label,
.inputfile-2 + label:hover {
    color: #722040;
}


.holiday-text{
    height:100px;
}


.btn-block+.btn-block{
    margin-top: 0rem;
}
.submit-button:hover {
    border: 1px solid white;
    background-color: black;
    color: white;
}


.form-group{
    margin-bottom: 2rem
}

#progressbar {
    margin-top: 20px;
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
}
#progressbar li {
    list-style-type: none;
    color: black;
    text-transform: uppercase;
    font-size: 16px;
    width: 25%;
    float: left;
    position: relative;
}
#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 40px;
    line-height: 40px;
    display: block;
    font-size: 18px;
    color: #333;
    background: white;
    border-radius: 40px;
    margin: 0 auto 5px auto;
}

/*progressbar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 16px;
    z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
    background: #000;
    color: white;
}


.review-container{
    margin-left: 2%;
    width:100%;

}

.review-container .col-sm-3{
    margin: 0 auto;

}

.personal-review-container{
    display: flex;
    justify-content: space-between;
}

.bank-review-container{
    display: flex;
    justify-content: space-between;
}



.personal-review-container{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top:20px;
    margin-bottom: 30px;
    width:100%;
    text-align: left;
    background-color: #e6e6e6;
}

.personal-review-container p{
    margin: 0;
}
.bank-review-container{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top:30px;
    margin-bottom: 20px;
    width:100%;
    text-align: left;
    background-color: #e6e6e6;
}

.review-availability-times{
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
}
.review-availability-times p{

    margin-left: 10px;
    font-size:16px;
    line-height: 1.2em;
    margin-bottom: 2px;


}
.review-times-right{
    order:2;
}

.submit-button{
    text-align: center;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    width: 250px;
    float: left;
    border-radius: 0;
}

@media only screen and (max-width: 1244px){
    .submit-button{
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .submit-button{
        margin: 0 auto;
        width:100%;
    }
    .time-picker .label-switch{
        width:100%;
    }
    .time-picker{
        display: block;
    }

    .time-container{
        width:100%;
    }
    .time-container .row{
        margin-bottom:10px;
    }

    .ant-time-picker{
        width:100%;
    }

    .small-remove-time{
        margin-left: 15px;
        width:30px;
    }

    .small-add-time{
        display: block;
        margin: 10px auto
    }
    .review-container{
        margin-left:20px;
    }


    .review-container h3{
        padding-top: 10px;
    }

    .personal-review-container{
        display: block;
        justify-content: normal;
    }

    .bank-review-container{
        display: block;
        justify-content: normal;
    }

    .time-container {
        display: block;
    }

    .welcome-video-container {
        width: 100% !important;
        height: 100% !important;
    }

    .welcome-video-player{
        width: auto !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 430px) {
    .submit-button{

    }
}



.start-form-container{
    text-align: left;
    max-width: 60%;
    padding-top: 2%;
}

.start-form-container li{
    font-size: 15px;
}

.submit-button-container{
    display: flex;
    padding-bottom: 20px;
}

.submit-review-button-container .submit-button{
    margin: 0 auto;
}


.form-container .checkbox{
    font-size: 15px;
}



.bank-form-container ul {
    list-style-type: circle;
}

.form-container .time-picker .btn {
    -webkit-appearance: none;
    background-color: white;
}

.form-container .time-picker .btn.active {
    background-color: lightgrey;
}

.z-index-select {
    z-index: 100;
}
 .citizenship-select .form-select :last-child {
    z-index:10;
}
.super-select .form-select :last-child {
    z-index:12;
}
.form-container .submit-button {
    margin-bottom: 1%;
}

.confirmation-form-container{
  text-align: left;
    line-height: 0.8;
}

.confirmation-vevo p{
    line-height: 1.4285em;

}

.button-review-container{
    display:flex;
    justify-content:center;
    align-items: flex-end;
    flex-flow: column;
}

.welcome-video-container{
    text-align: center;
    padding-bottom: 15px;
}
.welcome-video-container div{
    margin: 0 auto;
}

.dayHidden {
    display:none;
}

.formFallBackError{
    color: red;
}