.file-list .third-width {
    width: 33%;
    float: left;
    padding: 2%
}

.file-list .heading-text {
    text-align: center;
}

.file-list {
    width: 100%;
}

.file-list .approve-button{
    text-align: center;
    padding: 2% 10%;
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14pt;
    text-transform: uppercase;
    border: 1px solid white;
    background-color: black;
    color: white;
    cursor: pointer;
    float: left;
    margin: 0 2%;
    border-radius: 0;
}