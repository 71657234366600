
@media all and (min-width: 480px) {
    .Add form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.side-by-side{
    display:inline-block;
}

.left {
    float: left;
    width: 100%;
}


.form-heading{
    font-family: acumin-pro-condensed, sans-serif;
}

.add-form .help-block {
    font-size: 14px ;
}


.show-help{
    display:block;
    text-align: right;
}

.hide-help{
    display:none;
}

.success-sub-heading-text{
    font-weight: 800;
    font-size: 16pt;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 5px;
    color: #69cc07;
}
.fail-sub-heading-text{

    font-weight: 800;
    font-size: 16pt;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 5px;
    color: #ff5d5d;
}

.small-message {
    padding-bottom: 2%;
}