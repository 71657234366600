
/* Refresh task button styling below */
.refresh-task a {
    color: white;
    border: 1px solid white;
    padding:1em;
    margin:1%;
    cursor: pointer;

}

.refresh-task a:hover {
    color: black;
    border: 1px solid white;
    background-color:white
}