
.shipping-accordion-header-wrapper {
height:100px;
border-bottom: 1px solid rgba(63, 63, 68, 0.1);
margin-bottom: 15px;
}
.shipping-accordion-header-wrapper.urgent {
    background: #ef6b6b;
}

.shipping-accordion-header-wrapper.complete {
    background-color: lightgrey;
}

.shipping-accordion-header-wrapper.complete .arrow-col{
   display: none;
}
.shipping-accordion-header-wrapper.complete .shipping-urgency-wrapper{
    background-color: lightgrey;
    visibility: hidden;
}

/*Accordion header*/
.shipping-accordion-header{
    display: flex;
}
.shipping-accordion-header > div{
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.accordion-header-wrapper {
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.accordion-header-wrapper .stages-wrapper{
    width: 400px;
    position: absolute;
    bottom: 0.5rem;
}

.store-column {
    display: grid;
    justify-content: center;
    align-items: center;
}

.store-column svg {
    padding: 0 15px;
    height: 100px;
    box-sizing: content-box;
}

.shipment-details-column {
    display: grid;
    text-align: left;
}

.shipment-details-column span:first-child {
   padding-bottom: 0.2em;
}

.status-column {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 1em;
    justify-content: center;
    align-items: center;
}

.status-column > p {
    margin: 0;
}

/*Accordian header carrier*/
.carrier-column {
    height: 100px;
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: #D78D16
}

.carrier-column.urgent {
    background-color: #D71635;
}

.carrier-column svg {
    fill: white;
}

svg.store-logo.universal-store-logo .b {
    fill: white;
}

/*Accordion header components*/
.shipping-urgency{

}
.shipping-urgency.urgent {
    color: #EE3124;
}
.shipping-urgency.express{
color: #ffe835;
}
.shipping-urgency.regular{
color: #99B564;
}

.shipping-urgency.today{

}

.shipping-urgency.collect{
    color: #ff8913;
}

.shipping-accordion-header .shipping-id-wrapper {
    display: grid;
    align-content: center;
}
.shipping-id-wrapper > span {
    text-align: left;
}

.shipping-accordion-header-status p{
    text-align: right;
}

.left-accordion-header.download-pickslip{
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.left-accordion-header.download-pickslip svg{
    width:30px;
    height:30px
}

.left-accordion-header.download-pickslip:hover{
    color: #4fb2ff;
}

.dropdown-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.process-step-div span{
    color: #cccccc;
    font-size: 20px;
}

/*Blinker for overdue in click and collect*/
.overdue-red {
    animation: overdue-blinker 1s linear infinite;
    border: red 2px solid;
}

@keyframes overdue-blinker {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: red
    }
}

.five-yellow {
    animation: yellow-blinker 1s linear infinite;
    border: #ffdb2d 2px solid;
}

@keyframes yellow-blinker {
    from, to {
        border-color: transparent
    }
    50% {
        border-color: #ffdb2d
    }
}