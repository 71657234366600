.search-wrapper {
    width: 100%;
}

.search-wrapper input {
    float:left;
    font-size: 14pt;
}

.search-wrapper .search {
    width:100%;
    padding: 6%;
    border: 1px solid rgba(63, 63, 68, 0.05);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-bottom: 3%;
}

.product-search-wrapper input {
    float:left;
    font-size: 14pt;
}

.product-search-wrapper:after {
    content: '\f002';
    font-family: 'FontAwesome', serif;
    vertical-align: middle;
    display: inline-block;
    font-weight: 10;
    font-size:18pt;
    position: absolute;
    margin:5px 0 0 -2.5rem;
    color: #a5a5a5;
}

.product-search-wrapper .search-input {
    width:100%;
    padding: 2%;
    border: 1px solid rgba(15, 15, 16, 0.31);
    font-family: acumin-pro-condensed, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    margin-bottom: 3%;
}