.form-wrapper {

  .form-group{
    margin-bottom:1rem;
  }
  .form-label{
    float: left;
    text-align: left;
    font-weight: 700;
    margin-bottom:0;
  }
  .invalid-feedback{
    float: right;
    text-align: right;
  }

  .uni-invalid-feedback{
    float: right;
    text-align: right;
    margin-top:-1.5rem;
    width: 100%;
    font-size: 0.875em;
    color: #dc3545;
  }
}