$theme-colors: (
        "success": #b3cb7d
);

/* import the necessary Bootstrap files */
@import "~bootstrap/scss/bootstrap";

$uni-green:#87ad3e;

.LoaderButton.btn{
  border-radius: 0;
  min-height: 3rem;


  &.btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}


.LoaderButton.small{
  min-height: 2rem;
}

.LoaderButton.btn-success {
  color: #fff;
  @include button-variant($uni-green, $uni-green, #fff, lighten($uni-green,5%), lighten($uni-green, 10%), #fff);
}

.LoaderButton.btn-outline-btn-success {
  @include button-outline-variant($uni-green, #222222, lighten($uni-green,5%), $uni-green);
}