body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height:100vh;
}

#root {
  width:100%;
  height:100%;
}